import styled from "styled-components";

export default styled.input`
  border: 1px solid #ddd;
  padding: 0 8px;
  width: 100%;
  height: 32px;
  border-radius: var(--round-small);
  box-shadow: 0 0 0 1px transparent;
  transition: all 0.2s ease;

  :hover {
    border-color: #aaa;
  }

  &:focus {
    box-shadow: 0 0 0 1px var(--color-a);
    border-color: var(--color-a);
    outline: none;
  }

  &[readonly],
  &[readonly]:focus {
    background: #e6f8f2;
    outline: none;
  }
`;
