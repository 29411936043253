import { useEffect } from "react";
import { useActions, useStore } from "statux";
import { useAsyncEffect } from "use-async";
import { useUrl } from "crossroad";
import toast from "react-hot-toast";

import { Loading } from "./pages";
import api from "./api";

export default function Auth(props) {
  const setUser = useActions("user");
  const token = localStorage.getItem("token");
  useAsyncEffect(async () => {
    try {
      if (token) {
        setUser(await api.get("/admin"));
      }
    } catch (error) {
      toast.error(error.message);
      // Network errors (e.g. offline, server is down) shouldn't logout the user
      if (error.response && error.response.status === 401) {
        localStorage.removeItem("token");
      }
    }
  }, [token]);
  return props.children;
}

export const withUser = (Comp) => (props) => {
  const [, setUrl] = useUrl();
  const [user] = useStore("user");
  const token = localStorage.getItem("token");
  useEffect(() => {
    if (token) return;
    setUrl("/login?error=Could+not+find+your+session");
  }, [token, setUrl]);
  if (!user || !user.id || !user.folders) return <Loading />;
  return <Comp {...props} />;
};

export const dualAuth = (Priv, Pub) => (props) => {
  const [, setUrl] = useUrl();
  const [user] = useStore("user");
  const token = localStorage.getItem("token");
  useEffect(() => {
    if (token) return;
  }, [token, setUrl]);
  if (token && (!user || !user.id || !user.folders)) return <Loading />;
  if (!user || !user.id || !user.folders) return <Pub />;
  return <Priv {...props} />;
};
