import { useEffect } from "react";
import styled from "styled-components";
import { useActions, useStore } from "statux";

import { Close } from "./";
import Card from "../pages/Dashboard/Card";

const Content = styled.div`
  ${Card};
  min-width: 350px;
  min-height: 200px;
  display: flex;
  flex-direction: column;
  box-shadow: var(--hard-shadow);
  cursor: default;
`;

const Cover = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.3);
  cursor: pointer;

  // Classic visible/hidden flow
  opacity: 0;
  pointer-events: none;
  transition: all 0.2s ease;
  ${Content} {
    transform: scale(0.5);
  }

  ${(p) =>
    p.visible &&
    `
    opacity: 1;
    pointer-events: all;

    ${Content} {
      transform: scale(1);
    }
  `}
`;

const Title = styled.div`
  position: relative;
  display: flex;
  height: 50px;
  line-height: 20px;
  justify-content: space-between;
  background: var(--color-soft);
  padding: 15px;
`;

const Body = styled.div`
  flex-grow: 1;
  padding: var(--gap-small) var(--gap);
`;

const Modal = ({ id, title, children }) => {
  const [modal, setModal] = useStore("modal");
  const visible = id === modal || id === modal?.id;
  useEffect(() => {
    const onPress = (e) => {
      if (e.key === "Escape") setModal(null);
    };
    window.addEventListener("keydown", onPress);
    return () => window.removeEventListener("keydown", onPress);
  }, [setModal]);
  const onCoverClick = (e) => {
    if (!e || e.target !== e.currentTarget) return;
    setModal(null);
  };
  return (
    <Cover visible={visible} onClick={onCoverClick}>
      <Content>
        <Title>
          {title}
          <Close onClick={() => setModal(null)}>❌</Close>
        </Title>
        <Body>{visible ? children : null}</Body>
      </Content>
    </Cover>
  );
};

const Header = ({ children }) => {
  const setModal = useActions("modal");
  return (
    <Title>
      {children}
      <Close onClick={() => setModal(null)}>❌</Close>
    </Title>
  );
};

const withModal = (id) => (Component) => (props) => {
  const [modal, setModal] = useStore("modal");
  useEffect(() => {
    const onPress = (e) => {
      if (e.key === "Escape") setModal(null);
    };
    window.addEventListener("keydown", onPress);
    return () => window.removeEventListener("keydown", onPress);
  }, [setModal]);
  const onCoverClick = (e) => {
    if (!e || e.target !== e.currentTarget) return;
    setModal(null);
  };
  const visible = id === modal || id === modal?.id;
  if (!visible) return null;
  const close = () => setModal(null);
  return (
    <Cover visible={visible} onClick={onCoverClick}>
      <Content>
        <Component modal={modal} setModal={setModal} close={close} />
      </Content>
    </Cover>
  );
};

Modal.Header = Header;
Modal.Body = Body;

export default Modal;
export { Header, Body, withModal };
