import { useSelector } from "statux";
import styled from "styled-components";
import toast from "react-hot-toast";
import { Home, Tool, User } from "react-feather";

import ApiLink from "./ApiLink";
import Card from "./Card";

import Folders from "./Folders";
import Usage from "./Usage";
import api from "../../api";
import { Button, Layout as BaseLayout } from "../../components/";

const Layout = styled(BaseLayout)`
  margin: calc(2 * var(--gap)) auto 50px;
  gap: var(--gap);

  @media (max-width: 960px) {
    padding: 10px;
  }
`;

const ButtonCard = styled.a.attrs((p) => ({
  onClick: () => p.error && toast.error(p.error),
}))`
  ${Card};
  height: 50px;
  padding: 10px 15px;
  align-items: center;
  cursor: pointer;

  width: calc(50% - var(--gap) * 1 / 2);
  @media (min-width: 960px) {
    width: calc(25% - var(--gap) * 3 / 4);
  }

  &:hover {
    transform: scale(1.05);
  }

  &:active {
    transform: scale(1);
  }

  svg {
    margin-right: 7px;
  }
`;

const Title = styled.h1`
  font-weight: normal;
  line-height: 1;
  width: 100%;

  svg,
  img {
    display: inline-block;
    vertical-align: bottom;
  }
`;

const Logout = () => (
  <Button
    onClick={(e) => {
      localStorage.removeItem("token");
      api.post("/logout");
      window.location.href = "/";
    }}
  >
    Logout
  </Button>
);

const Header = styled.header`
  display: flex;
  width: 100%;
`;

export default function Admin() {
  const user = useSelector("user");
  const bucket = user.id.slice(0, 6);

  return (
    <Layout>
      <Header>
        <Title>
          <Home /> Dashboard
        </Title>
        <Logout />
      </Header>

      <ApiLink value={`https://api.filemon.io/${bucket}`} />

      <ButtonCard as="a" href="/settings">
        <Tool size={22} /> API Settings
      </ButtonCard>
      <ButtonCard as="a" href="/account">
        <User size={22} /> User Account
      </ButtonCard>

      <Usage />

      <Folders folders={user.folders} />
    </Layout>
  );
}
