import styled from "styled-components";
import toast from "react-hot-toast";
import { Clipboard } from "react-feather";

const Wrap = styled.div`
  display: inline-flex;
  position: relative;
  flex-wrap: nowrap;
  align-items: center;
  height: 30px;
  border-radius: var(--round-small);
  cursor: pointer;
  font-size: 14px;
  transition: all 0.2s ease;

  ${(p) => p.block && "width: 100%;"}

  :hover {
    transform: scale(1.05);
  }
  :active {
    transform: scale(1.02);
  }

  label {
    background: #eee;
    padding: 0 8px 0 10px;
    border: 1px solid #ccc;
    height: 30px;
    line-height: 28px;
    border-radius: var(--round-small) 0 0 var(--round-small);
    margin-right: -1px;
    cursor: inherit;
  }

  div {
    height: 30px;
    line-height: 28px;
    padding: 0 10px 0 8px;
    border: 1px solid #ccc;
    border-radius: 0 var(--round-small) var(--round-small) 0;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    flex-grow: 1;
  }

  svg {
    width: 16px;
    height: 16px;
    padding: 2px;
    border-radius: 4px;
    background: white;
    border: 1px solid #ccc;
    position: absolute;
    top: -6px;
    right: -6px;
    opacity: 0.5;
    transition: all 0.2s ease;
  }

  :hover svg {
    opacity: 1;
  }
`;

export default function DataLabel({ label, value, block, children }) {
  const onClick = (e) => {
    navigator.clipboard.writeText(value || children);
    toast.success(`Copied ${label || value || children} to your clipboard`);
  };
  return (
    <Wrap onClick={onClick} block={block}>
      {label && <label>{label}</label>}
      <div>{children}</div>
      <Clipboard />
    </Wrap>
  );
}
