import styled from "styled-components";
import { Tool } from "react-feather";

import { Layout as BaseLayout, Title } from "../../components/";

const Layout = styled(BaseLayout)`
  margin: calc(2 * var(--gap)) auto 50px;
  gap: var(--gap);

  @media (max-width: 960px) {
    padding: 10px;
  }
`;

export default function Settings() {
  return (
    <Layout>
      <Title>
        <Tool /> Settings
      </Title>
      <p>Hello world</p>
    </Layout>
  );
}
