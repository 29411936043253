import axios from "axios";

const api = axios.create({ baseURL: process.env.REACT_APP_API });

api.interceptors.request.use((config) => {
  const token = localStorage.getItem("token");
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

api.interceptors.response.use(
  (res) => res.data,
  (error) => {
    if (error.response && error.response.status === 401) {
      localStorage.removeItem("token");
      window.location.href = "/login?error=" + error.response.data.error;
    }
    const message = error.response?.data?.error;
    if (message) {
      console.log(message);
      throw new Error(message);
    }
    throw error;
  }
);

export default api;
