import styled from "styled-components";
import Prism from "prismjs";

const PreTag = styled.pre`
  background: #eee;
  padding: 20px;
  border-radius: var(--round);
  width: 100%;
  overflow-x: auto;

  text-shadow: 0 1px #fff;
  font-family: Consolas, Monaco, "Andale Mono", "Ubuntu Mono", monospace;
  font-size: 1em;
  text-align: left;
  white-space: pre;
  word-spacing: normal;
  word-break: normal;
  word-wrap: normal;
  line-height: 1.5;
  -moz-tab-size: 4;
  -o-tab-size: 4;
  tab-size: 4;
  -webkit-hyphens: none;
  -moz-hyphens: none;
  -ms-hyphens: none;
  hyphens: none;

  & ::selection,
  &::selection {
    text-shadow: none;
    background: #b3d4fc;
  }

  @media print {
    text-shadow: none;
  }
  padding: 1em;
  margin: 0.5em 0;
  overflow: auto;

  .token.cdata,
  .token.comment,
  .token.doctype,
  .token.prolog {
    color: #708090;
  }
  .token.punctuation {
    color: #999;
  }
  .token.namespace {
    opacity: 0.7;
  }
  .token.boolean,
  .token.constant,
  .token.deleted,
  .token.number,
  .token.property,
  .token.symbol,
  .token.tag {
    color: #905;
  }
  .token.attr-name,
  .token.builtin,
  .token.char,
  .token.inserted,
  .token.selector,
  .token.string {
    color: #690;
  }
  .language-css .token.string,
  .style .token.string,
  .token.entity,
  .token.operator,
  .token.url {
    color: #9a6e3a;
  }
  .token.atrule,
  .token.attr-value,
  .token.keyword {
    color: #07a;
  }
  .token.class-name,
  .token.function {
    color: #dd4a68;
  }
  .token.important,
  .token.regex,
  .token.variable {
    color: #e90;
  }
  .token.bold,
  .token.important {
    font-weight: 700;
  }
  .token.italic {
    font-style: italic;
  }
  .token.entity {
    cursor: help;
  }
`;

export default function Pre({ language = "javascript", code }) {
  // Find the first line that is not empty
  const pad = code
    .split("\n")
    .find((l) => !/^\s*$/.test(l))
    .match(/^\s*/)[0].length;
  const clean = code
    .split("\n")
    .map((l) => l.slice(pad))
    .join("\n")
    .trim();
  const html = Prism.highlight(clean, Prism.languages[language], language);

  return <PreTag dangerouslySetInnerHTML={{ __html: html }} />;
}
