import styled from "styled-components";

const Overlay = styled.div`
  opacity: 0;
  pointer-events: none;
  z-index: 10;
  transition: all 0.3s ease 0.1s;

  ${(p) =>
    p.loading &&
    `
  opacity: 1;
  pointer-events: all;
`}

  background: rgba(0, 0, 0, 0.3);
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
`;

const SpinnerElement = styled.div`
  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
  position: absolute;
  z-index: 10l;
  width: 100px;
  height: 100px;
  left: 50%;
  top: 50%;
  margin: -50px;
  border-radius: 50px;
  border: 10px solid red;
  border-color: var(--color-a) rgba(255, 255, 255, 0.5) rgba(255, 255, 255, 0.5);
  box-shadow: 0 0 0 3px rgba(255, 255, 255, 0.5),
    inset 0 0 0 3px rgba(255, 255, 255, 0.5);
  box-sizing: border-box;
  animation: spin 1.5s linear 0s infinite;
`;

export default function Spinner({ show }) {
  return (
    <Overlay loading={show}>
      <SpinnerElement />
    </Overlay>
  );
}
