import { useRef, useState, useEffect } from "react";

const defaultStyle = {};

export default function Squircle({
  className,
  width,
  height,
  radius = 0.6,
  style = defaultStyle,
  children,
}) {
  const ref = useRef();
  const [size, setSize] = useState(null);
  useEffect(() => {
    setSize(ref.current.getBoundingClientRect());
  }, []);
  const w = Math.round(+width || size?.width);
  const h = Math.round(+height || size?.height);
  const l = Math.sqrt(w ** 2 + h ** 2);
  const r = Math.round((l * (radius - 0.5)) / 5);
  const pathD = `
    M 0,${h / 2} C 0,${r}
    ${r},0 ${w / 2},0 ${w - r},0
    ${w},${r} ${w},${h / 2} ${w},${h - r}
    ${w - r},${h} ${w / 2},${h} ${r},${h}
    0,${h - r} 0,${h / 2}`
    .replace(/\n+/g, " ")
    .replace(/\s+/g, " ")
    .trim();
  const baseStyle = {
    display: "inline-block",
    clipPath: `path('${pathD}')`,
  };
  return (
    <div ref={ref} className={className} style={{ ...baseStyle, ...style }}>
      {children}
    </div>
  );
}
