import { useState } from "react";
import styled from "styled-components";
import { Check, X } from "react-feather";

const TagElement = styled.label`
  background: #fff;
  border-radius: 4px;
  font-size: 12px;
  line-height: 18px;
  height: 18px;
  padding: 1px 3px 1px 2px;
  white-space: nowrap;
  display: inline-flex;
  align-items: center;
  border-radius: 3px;
  cursor: pointer;
  transition: all 0.2s ease;

  background: #cfc;
  border: 1px solid green;
  color: green;

  &[hidden] {
    background: #fcc;
    border: 1px solid red;
    color: red;
  }

  :hover {
    opacity: 1;
    box-shadow: 0 0 5px -2px;
  }

  svg {
    margin-top: 1px;
  }

  span {
    margin-top: -2px;
    padding: 0 2px;
  }
`;

const Tags = styled.div`
  padding: 0;
  width: 350px;
  display: flex;
  gap: 5px;
  flex-wrap: wrap;
`;

const Tag = ({ children }) => {
  const [visible, setVisible] = useState(true);
  return (
    <TagElement hidden={!visible} onClick={(e) => setVisible(!visible)}>
      {visible && <input type="hidden" name="extensions[]" value={children} />}
      {visible ? <Check size={13} /> : <X size={13} />} <span>{children}</span>
    </TagElement>
  );
};

export default function FileTypes({ type = "all" }) {
  const imgs = [
    "jpg",
    "png",
    "webp",
    "gif",
    "jp2",
    "tiff",
    "avif",
    "heif",
    "raw",
  ];
  const all = [
    "jpg",
    "png",
    "webp",
    "gif",
    "jp2",
    "tiff",
    "avif",
    "heif",
    "raw",
    "mp4",
    "avi",
    "doc",
    "pdf",
    "txt",
    "xls",
    "zip",
    "rar",
    "other...",
  ];
  const types = type === "all" ? all : type === "image" ? imgs : [];
  return (
    <Tags>
      {types.map((tag) => (
        <Tag>{tag}</Tag>
      ))}
    </Tags>
  );
}
