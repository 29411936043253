import { useUrl, useQuery } from "crossroad";
import { useAsyncEffect } from "use-async";
import styled from "styled-components";
import toast from "react-hot-toast";

import api from "../../api";
import { Layout as BaseLayout } from "../../components";

const Layout = styled(BaseLayout)`
  margin: 10px auto;
  flex-direction: column;
`;

const Form = styled.form`
  position: relative;
  background: #fff;
  width: 100%;
  max-width: 400px;
  @media (min-width: 400px) {
    border-radius: var(--round-large);
  }
  margin: 0 auto;
  padding: calc(1.5 * var(--gap));
  box-shadow: var(--soft-shadow);

  h1 {
    font-weight: normal;
    color: #333;
    font-size: 26px;
    line-height: 1;
  }

  p {
    margin-top: 20px;
  }
`;

const Logo = styled.img`
  width: 80px;
  margin: 20px auto 20px;

  @media (min-width: 500px) {
    width: 120px;
  }
`;

export default function Verify() {
  const [token] = useQuery("token");
  const [, setUrl] = useUrl();
  useAsyncEffect(async () => {
    if (!token) return;
    try {
      await api.post("/verify?token=" + token);
      setUrl("/login?message=Email+verified,+now+you+can+log+in");
    } catch (error) {
      if (error?.response?.data?.error) {
        toast.error(error.response.data.error);
      } else {
        toast.error(error.message);
      }
    }
  }, []);
  if (!token) {
    return (
      <Layout>
        <Logo src="/logo.svg" />
        <Form>
          <h1>Check your email</h1>
          <p>
            We have sent you a confirmation email, please open it and click the
            link to verify your account
          </p>
        </Form>
      </Layout>
    );
  }
  return (
    <Layout>
      <Logo src="/logo.svg" />
      <Form>
        <h1>Verifying...</h1>
        <p>We are verifying your email, please sit tight</p>
      </Form>
    </Layout>
  );
}
