import { useState } from "react";
import styled from "styled-components";

import { Pre } from "app/components";

const Section = styled.div`
  margin: 30px auto 80px;
  width: calc(100% - 20px);
  max-width: 960px;
  display: flex;
  align-items: flex-top;
  flex-wrap: wrap;
  flex-grow: 0;
  flex-shrink: 1;
  gap: 20px;

  & > * {
    flex-grow: 1;
    flex-shrink: 0;
    width: 100%;
  }

  & > *:first-child {
    flex-grow: 0;
  }

  @media (min-width: 960px) {
    & > *:first-child {
      width: 40%;
    }
    & > *:last-child {
      width: calc(60% - 50px);
    }
  }

  pre {
    width: 100%;
  }
`;

const Pills = styled.div`
  width: 100%;
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
  gap: var(--gap);
`;

const Pill = styled.div`
  display: flex;
  justify-content: space-between;
  width: calc(50% - 10px);
  flex-grow: 1;
  flex-shrink: 0;
  padding: 8px 12px;
  @media (min-width: 960px) {
    padding: 10px 16px;
  }
  box-shadow: var(--shadow);
  border-radius: var(--round);
  cursor: pointer;
  transition: all 0.2s ease;
  transform: scale(1);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  :hover {
    transform: scale(1.05);
  }

  &::after {
    content: "→";
  }
`;

export default function UploadMethods() {
  const [selected, setSelected] = useState("ui");
  return (
    <Section>
      <div>
        <h2>Upload files</h2>
        <p>There are many ways to upload files to Filemon:</p>
        <Pills>
          <Pill onClick={() => setSelected("ui")}>File manager UI</Pill>
          <Pill onClick={() => setSelected("web")}>Web Component</Pill>
          <Pill onClick={() => setSelected("curl")}>With CURL</Pill>
          <Pill onClick={() => setSelected("browser")}>Axios</Pill>
          <Pill onClick={() => setSelected("react")}>React Helper</Pill>
          <Pill onClick={() => setSelected("vue")}>Vue Helper</Pill>
        </Pills>
      </div>
      <div>
        {selected === "ui" && (
          <img
            style={{ margin: "auto" }}
            alt="desktop UI"
            width="400"
            height="300"
            src="/transfer.svg"
          />
        )}
        {selected === "curl" && (
          <Pre
            code={`
              $ curl -X POST -F "img=@./test.jpg" \\
                https://filemon.io/

              https://filemon.io/vKYO5pPEJj6GUwJp
            `}
          />
        )}
        {selected === "node" && (
          <Pre
            code={`
              const URL = 'https://filemon.io/';

              const body = new FormData();
              body.append('img', fs.createReadStream(path));
              const config = { headers: body.getHeaders() };
              const res = await axios.post(URL, body, config);

              console.log(res.data);
              // https://filemon.io/vKYO5pPEJj6GUwJp
            `}
          />
        )}
        {selected === "browser" && (
          <Pre
            code={`
              const URL = 'https://filemon.io/';

              // Browser
              const body = new FormData(formElement);
              const res = await axios.post(URL, body);

              console.log(res.data);
              // https://filemon.io/vKYO5pPEJj6GUwJp
            `}
          />
        )}
        {selected === "web" && (
          <Pre
            code={`
              <script src="https://filemon.io/lib/web.js"></script>

              <form method="POST" action="/createuser">
                <file-inline name="profile" />
                <input name="name" />
                <button>Send</button>
              </form>
            `}
          />
        )}
        {selected === "fetch" && (
          <Pre
            code={`
              const res = await fetch("https://filemon.io/", {
                method: "POST",
                body: new FormData(event.target),
                headers: { "Content-Type": "multipart/form-data" }
              });

              console.log(await res.text());
              // https://filemon.io/vKYO5pPEJj6GUwJp
            `}
          />
        )}
        {selected === "react" && (
          <Pre
            code={`
              import Form from 'form-mate';  // Or any other 🤷‍♂️
              import { InlineFile } from 'filemonio';

              // { profile: "https://filemon.io/vKYO5pPEJj6GUwJp" ... }
              <Form onSubmit={data => console.log(data)}>
                <InlineFile name="profile" />
                <input name="name" />
                <button>Send</button>
              </Form>
            `}
          />
        )}
        {selected === "vue" && "Vue not yet available"}
      </div>
    </Section>
  );
}
