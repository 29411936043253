import styled from "styled-components";

import { Button } from "./components";

const NavBar = styled.nav`
  position: fixed;
  box-shadow: var(--soft-shadow);
  top: 0;
  left: 0;
  right: 0;
  height: 50px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #fff;
  padding: 0 10px;
  z-index: 100;

  @media all and (min-width: 980px) {
    padding: 0 calc(50% - 480px);
  }

  span {
    vertical-align: middle;
  }

  h2 {
    font-size: 18px;
  }

  button {
    box-shadow: none;
  }

  .menu > * {
    display: none;
  }

  .menu > *:nth-last-child(-n + 2) {
    display: inline-block;
  }

  @media all and (min-width: 600px) {
    .menu > * {
      display: inline-block;
    }
  }
`;

const Brand = styled.a`
  display: block;
  text-decoration: none;
  color: inherit;
  font-weight: bold;
  font-size: 20px;
`;

const Logo = styled.img.attrs({ width: 32, height: 32 })`
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
`;

export default function Nav() {
  const hasUser = Boolean(localStorage.getItem("token"));
  return (
    <NavBar>
      <Brand href="/">
        <Logo alt="logo" src="/logo.svg" />
        <span>Filemon</span>
      </Brand>
      <div className="menu">
        {hasUser ? (
          <>
            <Button as="a" href="/documentation">
              Docs
            </Button>{" "}
            <Button primary as="a" href="/">
              Dashboard
            </Button>
          </>
        ) : (
          <>
            <Button as="a" href="/#pricing">
              Pricing
            </Button>{" "}
            <Button as="a" href="/documentation">
              Docs
            </Button>{" "}
            <Button as="a" href="/login">
              Login
            </Button>{" "}
            <Button as="a" primary href="/register">
              Get Started
            </Button>
          </>
        )}
      </div>
    </NavBar>
  );
}
