import styled from "styled-components";

export default styled.button`
  position: absolute;
  top: 5px;
  right: 5px;
  width: 40px;
  height: 40px;
  font-size: 16px;
  border-radius: 8px;
  border: none;
  background: #fff;
  cursor: pointer;
  transition: all 0.15s ease;
  z-index: 100;
  opacity: 0.6;
  filter: saturate(0.5);

  :hover {
    opacity: 1;
    filter: saturate(1);
  }

  :active {
    transform: scale(0.9);
  }
`;
