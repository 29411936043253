import styled from "styled-components";

const Icon = styled.img`
  display: inline;
  position: ${(p) => p.position};
  top: -4px;
  left: ${(p) => (p.position === "absolute" ? "100%" : "0")};
  margin-left: 2px;
  opacity: 0.5;
  filter: saturate(0);
  cursor: pointer;
  z-index: 10;
  transition: all 0.2s ease;

  :hover {
    opacity: 0.8;
    filter: saturate(1);
  }
`;

export default function Info({ absolute, children }) {
  if (!children) return null;
  return (
    <Icon
      position={absolute ? "absolute" : "relative"}
      alt=""
      width="16"
      height="16"
      src="/info.svg"
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        alert(children);
      }}
    />
  );
}
