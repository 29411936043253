import styled from "styled-components";

export default styled.button`
  display: inline-block;
  background: #fff;
  border: none;
  border-radius: var(--round-small);
  height: 32px; /* 32 = 24 + 3 * 2 + 1 * 2 */
  line-height: 24px;
  padding: 3px 15px;
  border: 1px solid #ddd;
  cursor: pointer;
  transition: all 0.2s ease;
  text-decoration: none;
  color: inherit;
  text-align: center;
  white-space: nowrap;

  box-shadow: 0 0 0 1px transparent;
  text-decoration: none;

  :hover {
    border-color: #aaa;
  }

  &:focus {
    box-shadow: 0 0 0 1px var(--color-a);
    border-color: var(--color-a);
    outline: none;
  }

  &:disabled {
    cursor: not-allowed;
  }

  ${(p) =>
    p.primary &&
    `
    border: none;
    border-color: #333;
    background: #333;
    color: white;

    :hover {
      background: #555;
      border-color: #555;
    }
  `}

  ${(p) =>
    p.dangerous &&
    `
    border-color: red;
    background: #fee;
    color: red;

    :hover {
      background: #fcc;
      border-color: red;
    }
  `}
`;
