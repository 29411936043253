// import { useState } from "react";
// import styled from "styled-components";

// const Section = styled.div`
//   margin: 30px auto 80px;
//   width: calc(100% - 20px);
//   max-width: 960px;
//   display: flex;
//   align-items: flex-top;
//   flex-wrap: wrap;
//   flex-grow: 0;
//   flex-shrink: 1;
//   gap: 20px;
//
//   & > * {
//     flex-grow: 1;
//     flex-shrink: 0;
//     width: 100%;
//   }
//
//   & > *:first-child {
//     flex-grow: 0;
//   }
//
//   @media (min-width: 960px) {
//     & > *:first-child {
//       width: 40%;
//     }
//     & > *:last-child {
//       width: calc(60% - 50px);
//     }
//   }
//
//   pre {
//     width: 100%;
//   }
// `;
//
// const Tabs = styled.div`
//   display: flex;
//   width: 100%;
//   gap: 10px;
// `;
//
// const Tab = styled.div`
//   flex-grow: 1;
//   background: white;
//   border-radius: var(--round-small) var(--round-small) 0 0;
//   border: 2px solid #ddd;
//   padding: 5px 12px;
//   cursor: pointer;
//   ${(p) => p.open && `opacity: 1; border-bottom: none;`}
// `;
//
// const Wrap = styled.div`
//   position: relative;
//   height: 200px;
// `;
//
// const Content = styled.div`
//   /* background: red; */
//   padding: 5px 12px;
//   border: 2px solid #ddd;
//   margin-top: -2px;
//   z-index: -1;
//   border-radius: 0 0 var(--round-small) var(--round-small);
//   position: absolute;
//   top: 0;
//   left: 0;
//   width: 100%;
//   height: 200px;
//   opacity: 0;
//   background: white;
//
//   ${(p) => p.open && `opacity: 1;`}
// `;

export default function Demo() {
  return null;
  // const [open, setOpen] = useState(1);
  // return (
  //   <Section>
  //     <div>
  //       <Tabs>
  //         <Tab open={open === 1} onClick={() => setOpen(1)}>
  //           1. Upload Image
  //         </Tab>
  //         <Tab open={open === 2} onClick={() => setOpen(2)}>
  //           2. Manipulate Image
  //         </Tab>
  //       </Tabs>
  //       <Wrap>
  //         <Content open={open === 1}>A</Content>
  //         <Content open={open === 2}>B</Content>
  //       </Wrap>
  //     </div>
  //     <div>B</div>
  //   </Section>
  // );
}
