import { useState } from "react";
import toast from "react-hot-toast";
import Form, { FormLoading } from "form-mate";
import styled from "styled-components";
import { useActions } from "statux";

import api from "../../api.js";
import { Button, Input, Modal, Spinner } from "../../components";

import Label from "./Label";
import FileTypes from "./FileTypes";

const Subtext = styled.div`
  font-size: 12px;
`;

const Group = styled.div`
  display: flex;
  width: 100%;
`;

const TabEl = styled.label`
  flex-grow: 1;
  border-radius: 0;
  height: 30px;

  :first-child span {
    border-radius: var(--round-small) 0 0 var(--round-small);
  }

  :last-child span {
    border-radius: 0 var(--round-small) var(--round-small) 0;
  }

  input {
    opacity: 0;
    width: 0;
    height: 0;
    position: absolute;
    pointer-events: none;
  }

  span {
    display: inline-block;
    width: 100%;
    height: 100%;
    text-align: center;
    background: #eee;
    cursor: pointer;
  }

  input[disabled] + span {
    background: #fee;
    cursor: not-allowed;
  }

  input:checked + span {
    background: #eee;
    box-shadow: inset 0 0 0 3px var(--color-a);
  }
`;

const Tab = ({ onChange, children, title, ...args }) => {
  return (
    <TabEl>
      <input
        onChange={(e) => onChange(e.target.value)}
        type="radio"
        name="tab"
        {...args}
      />
      <span title={title}>{children}</span>
    </TabEl>
  );
};

const Half = styled.div`
  display: flex;
  gap: 20px;
  width: 350px;
  font-size: 14px;

  & > * {
    flex-grow: 0;
    flex-shrink: 1;
  }
`;

export default function CreateTempleate() {
  const setUser = useActions("user");
  const setModal = useActions("modal");
  const [tab, setTab] = useState("all");
  return (
    <Modal id="template" title="Create Template">
      <Form
        onSubmit={async (data) => {
          await api.post("/templates", data);
          toast.success(`Created template "${data.name}"`);
          const updatedUser = await api.get("/admin");
          setUser(updatedUser);
          setModal(null);
        }}
        onError={(error) => toast.error(error.message)}
      >
        <FormLoading>{(loading) => <Spinner show={loading} />}</FormLoading>
        <Label text="Name">
          <Input name="name" placeholder="Profile" autoFocus required />
          <Subtext>An intuitive but simple name</Subtext>
        </Label>

        <Label as="div" text="Category">
          <Group>
            <Tab value="all" onChange={setTab} defaultChecked>
              All types
            </Tab>
            <Tab value="image" onChange={setTab}>
              Images
            </Tab>
            <Tab value="video" disabled title="Not yet available">
              Videos
            </Tab>
          </Group>
          <Subtext>Images and Videos have extra options</Subtext>
        </Label>

        <Label as="div" text="Accept">
          <FileTypes type={tab} />
          <Subtext>Select only the file types you accept</Subtext>
        </Label>

        {tab === "image" && (
          <Label text="Image Size">
            <Half>
              <div>
                <div>Width:</div>
                <Input
                  name="height"
                  type="number"
                  min="0"
                  step="1"
                  placeholder="0"
                />
              </div>
              <div>
                <div>Height:</div>
                <Input
                  name="width"
                  type="number"
                  min="0"
                  step="1"
                  placeholder="0"
                />
              </div>
            </Half>
            <Subtext>Leave 0 to use the original width and height</Subtext>
          </Label>
        )}

        <Label as="div">
          <Button primary>Create</Button>
        </Label>
      </Form>
    </Modal>
  );
}
