import styled from "styled-components";
import { FileText } from "react-feather";

import { Title, Layout as BaseLayout } from "../../components";

const Layout = styled(BaseLayout)`
  margin: calc(2 * var(--gap)) auto 50px;
  gap: var(--gap);

  @media (max-width: 960px) {
    padding: 10px;
  }
`;

export default function Documentation() {
  return (
    <Layout>
      <Title>
        <FileText /> Documentation
      </Title>
      <p>WIP</p>
    </Layout>
  );
}
