import { css } from "styled-components";

export default css`
  position: relative;
  display: flex;

  background: #fff;
  color: inherit;
  box-shadow: var(--shadow-soft);
  border-radius: 10px;
  border: none;
  text-decoration: none;
  font-size: 18px;
  transition: all 0.15s ease;
  overflow: hidden;
`;
