import { useUrl } from "crossroad";
import { useActions } from "statux";
import FormMate, { FormLoading } from "form-mate";
import styled from "styled-components";
import toast from "react-hot-toast";

import { Button, Input, Layout as BaseLayout, Spinner } from "../../components";
import api from "../../api";

const Layout = styled(BaseLayout)`
  margin: 10px auto;
  flex-direction: column;
`;

const Form = styled(FormMate)`
  position: relative;
  background: #fff;
  width: 100%;
  max-width: 450px;
  overflow: hidden;

  @media (min-width: 500px) {
    border-radius: var(--round-large);
  }
  margin: 0 auto;
  padding: calc(1.5 * var(--gap));
  box-shadow: var(--soft-shadow);

  h1 {
    font-weight: normal;
    color: #333;
    font-size: 26px;
  }
`;

const Logo = styled.img`
  width: 80px;
  margin: 20px auto 20px;

  @media (min-width: 500px) {
    width: 120px;
  }
`;

const Label = styled.label`
  display: flex;
  align-items: center;
  margin: 15px 0 0;
  border-radius: var(--round-small);
  cursor: pointer;

  @media (max-width: 500px) {
    flex-wrap: wrap;
  }

  span {
    flex-grow: 1;
    flex-shrink: 0;
  }

  a {
    padding: 0 5px;
  }
`;

const Text = styled.div`
  width: 100%;
  flex-shrink: 0;
  @media (min-width: 500px) {
    width: 90px;
  }
`;

const Plans = styled.div`
  display: flex;
  background: #eee;
  width: 100%;
  border-radius: var(--round-small);
`;

const PlanStyle = styled.div`
  position: relative;
  text-align: center;
  padding: 10px;
  cursor: pointer;
  transition: all 0.2s ease;
  box-shadow: inset 0 0 0 4px transparent;

  :hover {
    background: #ddd;
  }
`;

const PlanWrap = styled.label`
  width: 33.33%;

  &:first-child ${PlanStyle} {
    border-radius: var(--round-small) 0 0 var(--round-small);
  }
  &:last-child ${PlanStyle} {
    border-radius: 0 var(--round-small) var(--round-small) 0;
  }

  input {
    opacity: 0;
    position: absolute;
  }

  input:focus + ${PlanStyle} {
    box-shadow: inset 0 0 0 6px #333;
  }

  input:checked + ${PlanStyle} {
    box-shadow: inset 0 0 0 6px #00c2f5;
  }
`;

const Plan = ({ value, children }) => {
  return (
    <PlanWrap>
      <input required type="radio" name="plan" value={value} />
      <PlanStyle>{children}</PlanStyle>
    </PlanWrap>
  );
};

export default function Login() {
  const setUser = useActions("user");
  const [, setUrl] = useUrl();
  const onRegister = async (body) => {
    const user = await api.post("/register", body);
    setUser(user);
    if (user.token) {
      localStorage.setItem("token", user.token);
    }
    setUrl("/verify");
    toast.success("Registered successfully!");
  };
  const onError = async (err) => {
    toast.error(err.message || "Please try again later");
  };
  return (
    <Layout>
      <Logo src="/logo.svg" />
      <Form onSubmit={onRegister} onError={onError}>
        <FormLoading>{(loading) => <Spinner show={loading} />}</FormLoading>
        <h1>Create New Account</h1>
        <Label>
          <Text>Name:</Text>
          <Input required autoFocus name="name" placeholder="John Doe" />
        </Label>
        <Label>
          <Text>Email:</Text>
          <Input
            required
            name="email"
            type="email"
            placeholder="john@doe.com"
          />
        </Label>
        <Label>
          <Text>Password:</Text>
          <Input
            required
            name="password"
            type="password"
            placeholder="*******"
          />
        </Label>
        <Label>
          <Text></Text>
          <Plans>
            <Plan value="commons">
              Commons
              <br />
              $0/m
            </Plan>
            <Plan value="startup">
              Startup
              <br />
              $10/m
            </Plan>
            <Plan value="company">
              Company
              <br />
              $100/m
            </Plan>
          </Plans>
        </Label>
        <Label>
          <Text></Text>
          <div>
            <input name="tos" type="checkbox" required /> Agree with the
            <a href="/terms" target="_blank">
              Terms of Service
            </a>
          </div>
        </Label>
        <Label as="div">
          <Text></Text>
          <Button primary>Register</Button>
          <span></span>
          <a href="/login">Login</a>
        </Label>
      </Form>
    </Layout>
  );
}
