import { useRef, useState } from "react";
import toast from "react-hot-toast";
import styled from "styled-components";
import { useStore } from "statux";
import { useParams } from "crossroad";
import { Upload, Settings } from "react-feather";

import { Squircle } from "../../components";
import api from "../../api";

const UploadContainer = styled(Squircle)`
  background: rgba(255, 255, 255, 0.75);
  position: relative;
  display: block;
  width: calc(100% / 3 - var(--gap) * 2 / 3);
  @media (min-width: 960px) {
    width: calc(100% / 6 - var(--gap) * 5 / 6);
  }

  aspect-ratio: 1;
  flex-grow: 0;
  flex-shrink: 0;
  overflow: hidden;
  cursor: pointer;
  transition: all 0.15s ease;

  :hover {
    transform: scale(1.05);
  }

  label {
    display: flex;
    flex-direction: column;
    position: relative;
    font-family: monospace;
    width: 100%;
    height: 100%;
    color: white;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    text-align: center;
    z-index: 10;
    transition: all 0.15s ease;
  }

  ${(p) =>
    p.loading &&
    `
    border: none;

    &:before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 5;
      background-size: 800px 800px;
      background-position: -500px -500px;
      background-image: linear-gradient(
        135deg,
        rgba(150, 150, 150, 0.5) 45%,
        rgba(255, 255, 255, 0.3) 50%,
        rgba(150, 150, 150, 0.5) 55%
      );
      animation: shine 1.2s infinite linear;
    }
  `}
  @keyframes shine {
    to {
      background-position: 0 0;
    }
  }
  label:hover {
    opacity: 1;
  }
  input {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: none;
  }
  img {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 1;
  }
  ${(p) =>
    p.loading &&
    `
  svg {
    animation: turn 2s infinite linear;
  }
  `}

  @keyframes turn {
    to {
      transform: rotate(360deg);
    }
  }
`;

export default function UploadFile({ onUpload }) {
  const ref = useRef();
  const [user] = useStore("user");
  const [uploading, setUploading] = useState([]);
  const { template } = useParams("/folder/:template");
  const onChange = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    const files = [...e.target.files];

    try {
      const uploads = await Promise.all(
        files.map((file, index) => {
          return new Promise((done) => {
            const reader = new FileReader();
            reader.onloadend = function () {
              done({ src: reader.result, progress: 0 });
            };
            reader.readAsDataURL(file);
          });
        })
      );
      setUploading(uploads);

      await Promise.all(
        files.map(async (file, index) => {
          const formData = new FormData();
          formData.append("image", file);
          const config = {
            headers: { "Content-Type": "multipart/form-data" },
            onUploadProgress: (e) =>
              setUploading((all) => {
                return all.map((f, i) => {
                  if (i === index) {
                    return {
                      ...f,
                      progress: Math.round((100 * e.loaded) / e.total),
                    };
                  }
                  return f;
                });
              }),
          };
          // Default, base folder
          if (["all", "unclassified"].includes(template)) {
            await api.post("/" + user.id, formData, config);
            // A specific template to be used
          } else {
            await api.post("/t/" + template, formData, config);
          }
        })
      );

      if (onUpload) {
        await onUpload();
      }

      toast.success("Uploaded file" + (e.target.files.length > 1 ? "s" : ""));
      setUploading([]);
    } catch (error) {
      const message =
        error.response?.data?.error ||
        "Error uploading image, please try again later";
      toast.error(message);
      setUploading([]);
    }
  };
  return (
    <>
      <UploadContainer>
        <label ref={ref}>
          <Upload size={40} color="#888" strokeWidth="1px" />
          <input type="file" multiple onChange={onChange} />
        </label>
      </UploadContainer>
      {uploading.map((f) => (
        <UploadContainer loading>
          <label>
            {f.progress === 100 ? (
              <Settings color="white" size={40} />
            ) : (
              f.progress + "%"
            )}
          </label>
          <img src={f.src} alt="" />
        </UploadContainer>
      ))}
    </>
  );
}
