import styled from "styled-components";

import Nav from "../../Nav";
import { Button, Footer, Info, Main } from "../../components";

import Demo from "./Demo.js";
import UploadMethods from "./UploadMethods";
import Pricing from "./Pricing";

const Wip = styled.div`
  padding: 20px;
  text-align: center;
  background: #ffd;
  margin-top: 50px;
`;

const HeroWrap = styled.div`
  background: #e6f8f2;
  padding: 0 0 60px;
`;

const Hero = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: calc(100% - 20px);
  max-width: 960px;
  margin: 0 auto;
  min-height: 60vh;

  ul {
    list-style: none;
    padding: 0;
  }

  & > * {
    width: 100%;
  }

  @media (min-width: 960px) {
    & > * {
      width: 50%;
    }
  }
`;

const Text = styled.div`
  line-height: 1.75;
`;

const Browser = styled.div`
  position: relative;
  margin: 50px auto 30px;
  border: 2px solid var(--color-primary);
  border-top-width: 20px;
  border-radius: 8px 8px 0px 0px;

  :before,
  :after {
    content: "";
    position: absolute;
    width: 9px;
    height: 9px;
    background: #f55;
    top: -14px;
    left: 8px;
    border-radius: 50%;
    z-index: 10;
  }

  :after {
    left: 24px;
    background: #3d3;
  }
`;

const HeroImg = styled.img`
  width: 100%;
  display: block;
  height: auto;
`;

const Actions = styled.div`
  position: relative;
  margin-top: 15px;
  display: flex;
`;

const Arrow = styled.div`
  position: absolute;
  pointer-events: none;
  top: 25px;
  left: 45px;
  width: 240px;
  color: black;
  display: flex;
  align-items: center;

  img {
    filter: saturate(0);
    width: 40px;
    height: 26px;
    display: inline-block;
  }

  p {
    margin-top: 20px;
    font-size: 14px;
    opacity: 0.8;
  }
`;

const Claims = styled.div`
  position: relative;
  top: -50px;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  margin-bottom: -50px;
  padding: 0 10px;
  background: #fff;
  box-shadow: var(--soft-shadow);

  @media (min-width: 960px) {
    border-radius: var(--round-large);
  }
`;

const Claim = styled.div`
  flex-grow: 1;
  flex-shrink: 0;
  height: 100px;
  width: 33%;
  padding-top: 5px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  strong {
    white-space: nowrap;
    font-size: 28px;
    margin-top: 5px;
    color: #008056;
    line-height: 1.2;
  }

  img {
    display: inline-block;
    width: 16px;
    vertical-align: top;
    margin-right: -18px;
    margin-left: 2px;
    filter: saturate(0);
    cursor: pointer;
    transition: all 0.2s ease;
    opacity: 0.6;
  }
  img:hover {
    filter: saturate(1);
    opacity: 1;
  }

  p {
    margin: 0 0 10px;
  }
`;

export default function Home() {
  return (
    <Main>
      <Nav />

      <Wip>
        ⚠️ WIP,{" "}
        <a href="https://twitter.com/fpresencia">follow me on Twitter</a> for
        updates ⚠️
      </Wip>

      <HeroWrap>
        <Hero>
          <Text>
            <h1>Filemon</h1>
            <p>An API to manage images, videos, and other files:</p>
            <ul>
              <li>🌄 Upload files with POST requests or using our UI.</li>
              <li>🔧 Edit on the fly with resize, compress, blur, etc.</li>
              <li>💰 Save money and time with our ultra fast CDNs.</li>
              <li>🤖 Serve optimized assets for the user's browser.</li>
            </ul>
            <Actions>
              <Button as="a" primary href="/register">
                Get Started
              </Button>
              &nbsp;
              <Button as="a" href="/demo">
                Quick Demo
              </Button>
              <Arrow>
                <img alt="" width="198" height="138" src="/redarrow.png" />
                <p>No card required</p>
              </Arrow>
            </Actions>
          </Text>
          <div>
            <Browser>
              <HeroImg
                alt="logo"
                src="/screenshot.webp"
                width="460"
                height="320"
              />
            </Browser>
          </div>
        </Hero>
      </HeroWrap>

      <Claims>
        <Claim>
          <strong>
            0.25x
            <Info>Compared to the competition we cost 1/4 as little</Info>
          </strong>
          <p>the cost</p>
        </Claim>
        <Claim>
          <strong>
            50%
            <Info>
              Using next-gen image formats like WEBP or AVIF you'll deliver the
              same quality with just half the size
            </Info>
          </strong>
          <p>smaller files</p>
        </Claim>
        <Claim>
          <strong>
            2.3x
            <Info>
              We (will) use BunnyCDN on top of Filemon for faster global
              distribution of files
            </Info>
          </strong>
          <p>faster loads</p>
        </Claim>
      </Claims>

      <UploadMethods />

      <Demo />

      <Pricing />

      <Footer />
    </Main>
  );
}
