import styled from "styled-components";

const LabelWrap = styled.label`
  width: 100%;
  margin: var(--gap) 0;

  @media (min-width: 600px) {
    display: flex;
    gap: var(--gap);

    & > *:first-child {
      width: 100px;
      flex-shrink: 0;
    }

    & > *:last-child {
      width: 100%;
      flex-grow: 1;
    }
  }
`;

export default function Label({ text, children, ...extra }) {
  return (
    <LabelWrap {...extra}>
      <div>{text}</div>
      <div>{children}</div>
    </LabelWrap>
  );
}
