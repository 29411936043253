import { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { useStore } from "statux";
import { usePath, useParams } from "crossroad";

import { Close, DataLabel } from "../../components";

const baseURL = process.env.REACT_APP_API;

const AllPage = styled.div`
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  scroll: hidden;
  background: repeating-linear-gradient(
    45deg,
    #aaa,
    #aaa 10px,
    #ccc 10px,
    #ccc 30px
  );
  transition: all 0.6s ease;

  ${(p) =>
    p.open
      ? ``
      : `
    pointer-events: none;
    opacity: 0;
  `}
`;

const Wrap = styled.div`
  width: 1250px;
  max-width: 100%;
  height: 100vh;
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 0 auto;
  transition: all 0.3s ease;

  @media (min-width: 1250px) {
    flex-direction: row;
    height: 100%;
    align-items: stretch;
    justify-content: center;
  }

  ${(p) =>
    p.open
      ? ``
      : `
    transform: scale(0.6);
    opacity: 0;
  `}

  hr {
    display: block;
    width: 100%;
    opacity: 0;
  }
`;

const ImgWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #aaa;
  width: 900px;
  max-width: 100%;
  max-height: 70vh;
  @media (min-width: 1250px) {
    width: auto;
    max-height: none;
  }
  aspect-ratio: 1;

  img {
    max-width: 100%;
    max-height: 100%;
  }
`;

const Content = styled.header`
  width: 100%;
  flex-grow: 1;
  width: 900px;
  max-width: 100%;
  height: 100vh;
  overflow: auto;

  @media (min-width: 1250px) {
    width: 400px;
    max-width: none;
    height: 100%;
    flex-grow: 0;
    flex-shrink: 0;
    position: relative;
  }
  padding: 10px 20px 80px 20px;
  background: white;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  gap: 10px;

  h1,
  h2,
  h3 {
    display: block;
    width: 100%;
  }

  h3 {
    margin-top: 20px;
  }
`;

const Block = styled.div`
  width: 100%;
`;

const Loading = () => {
  return "Loading...";
};

export default function Lightbox() {
  const { folder } = useParams("/folder/:folder/*");
  const { file: open } = useParams("/folder/:folder/:file");
  const [path, setPath] = usePath();
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  const [user] = useStore("user");
  const file = (user.folders.find((f) => f.id === folder) || []).files.find(
    (f) => f.id === open
  ) || { created: "" };

  useEffect(() => {
    if (open) {
      document.body.classList.add("noscroll");
    } else {
      document.body.classList.remove("noscroll");
    }
    return () => document.body.classList.remove("noscroll");
  }, [open]);

  const close = useCallback(() => {
    setPath(path.split("/").slice(0, -1).join("/"));
    setTimeout(() => {
      setDimensions({ width: 0, height: 0 });
    }, 300);
  }, [path, setPath]);

  useEffect(() => {
    const onPress = (e) => {
      if (e.key === "Escape") {
        e.preventDefault();
        close();
      }
    };
    window.addEventListener("keydown", onPress);
    return () => window.removeEventListener("keydown", onPress);
  }, [close]);

  const url = `${baseURL}f/${open}`;

  const onLoad = (e) => {
    console.log(e);
    setDimensions({
      width: e.target.naturalWidth,
      height: e.target.naturalHeight,
    });
  };

  return (
    <AllPage open={Boolean(open)}>
      <Wrap open={Boolean(open)}>
        {url ? (
          <ImgWrap>
            <img onLoad={onLoad} src={url} alt="" />
            {dimensions.width ? null : <Loading />}
          </ImgWrap>
        ) : null}
        <Content>
          <Close onClick={close}>❌</Close>
          <h2>File Info</h2>
          <DataLabel label="URL" block>
            {url}
          </DataLabel>
          <DataLabel label="ID">{file.id}</DataLabel>
          <DataLabel label="Type">{file.type}</DataLabel>
          <DataLabel label="Size" value={file.size}>
            {(file.size / (file.size > 1e6 ? 1e6 : 1000)).toFixed(1)}
            {file.size > 1e6 ? "mb" : "kb"}
          </DataLabel>
          <DataLabel label="Width">{dimensions.width || "..."}px</DataLabel>
          <DataLabel label="Height">{dimensions.height || "..."}px</DataLabel>
          <DataLabel label="Created">
            {file.created.split(".")[0].replace("T", " ")}
          </DataLabel>

          <Block>
            <a target="_blank" rel="noreferrer" href={`${baseURL}${file.id}`}>
              Open in new tab
            </a>
          </Block>

          <h3>Original</h3>
          <DataLabel label="URL" block>{`${baseURL}raw/${file.id}`}</DataLabel>
          <DataLabel label="Name">{file.name}</DataLabel>

          <Block>
            <a
              target="_blank"
              rel="noreferrer"
              href={`${baseURL}raw/${file.id}`}
            >
              See Original
            </a>
          </Block>
        </Content>
      </Wrap>
    </AllPage>
  );
}
