import { useState } from "react";
import toast from "react-hot-toast";
import Form from "form-mate";
import styled from "styled-components";
import { useActions, useSelector } from "statux";

import api from "../../api.js";
import { Button, Modal, Input, withModal } from "../../components";

import Label from "./Label";
import FileTypes from "./FileTypes";

// Below this number a simple warning, above this number an ellaborated one
const limitDeletion = 10;

const Subtext = styled.div`
  font-size: 12px;
`;

const Group = styled.div`
  display: flex;
  width: 100%;
`;

const TabEl = styled.label`
  flex-grow: 1;
  border-radius: 0;
  height: 30px;

  :first-child span {
    border-radius: var(--round-small) 0 0 var(--round-small);
  }

  :last-child span {
    border-radius: 0 var(--round-small) var(--round-small) 0;
  }

  input {
    opacity: 0;
    width: 0;
    height: 0;
    position: absolute;
    pointer-events: none;
  }

  span {
    display: inline-block;
    width: 100%;
    height: 100%;
    text-align: center;
    background: #eee;
    cursor: pointer;
  }

  input[disabled] + span {
    background: #fee;
    cursor: not-allowed;
  }

  input:checked + span {
    background: #eee;
    box-shadow: inset 0 0 0 3px var(--color-a);
  }
`;

const Tab = ({ onChange, children, title, ...args }) => {
  return (
    <TabEl>
      <input
        onChange={(e) => onChange(e.target.value)}
        type="radio"
        name="tab"
        {...args}
      />
      <span title={title}>{children}</span>
    </TabEl>
  );
};

const Half = styled.div`
  display: flex;
  gap: 20px;
  width: 350px;
  font-size: 14px;

  & > * {
    flex-grow: 0;
    flex-shrink: 1;
  }
`;

export default withModal("settings")(function Settings({ modal, close }) {
  const [tab, setTab] = useState("all");
  const setUser = useActions("user");
  const folders = useSelector("user.folders");
  const folder = folders.find((f) => f.id === modal.folder);
  if (!folder) return null;
  const renameFolder = async (data) => {
    try {
      await api.put("/templates/" + folder.id, data);
      toast.success(`Renamed template "${folder.name}" to "${data.name}"`);
      const updatedUser = await api.get("/admin");
      setUser(updatedUser);
      close();
    } catch (error) {
      toast.error(error.message);
    }
  };
  const deleteFolder = async () => {
    try {
      if (folder.count && folder.count < limitDeletion) {
        const res = window.confirm(
          `You are deleting ${folder.count} files, confirm deletion?`
        );
        if (!res) return toast.error("Cancelled");
      }
      if (folder.count && folder.count > limitDeletion) {
        const res = window.prompt(
          `You are deleting ${folder.count} files and it will take a while, please type this number to delete them:`
        );
        if (!/^\d+$/.test(res) || parseInt(res) !== folder.count) {
          return toast.error("Cancelled");
        }
      }
      await api.delete("/templates/" + folder.id);
      toast.success(
        `Deleted ${folder.length ? "" : "empty"} template "${folder.name}"`
      );
      const updatedUser = await api.get("/admin");
      setUser(updatedUser);
      close();
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <div>
      <Modal.Header>{folder.name} Settings</Modal.Header>
      <Modal.Body>
        <Form
          onSubmit={renameFolder}
          onError={(error) => toast.error(error.message)}
        >
          <Label text="ID">
            <Input name="id" defaultValue={folder.id.slice(0, 6)} readOnly />
            <Subtext>The ID (prefix) of the template</Subtext>
          </Label>

          <Label text="Name">
            <Input
              name="name"
              placeholder="Profile"
              defaultValue={folder.name}
              autoFocus
            />
            <Subtext>An intuitive but simple name</Subtext>
          </Label>

          <Label as="div" text="Category">
            <Group>
              <Tab value="all" onChange={setTab} defaultChecked>
                All types
              </Tab>
              <Tab value="image" onChange={setTab}>
                Images
              </Tab>
              <Tab value="video" disabled title="Not yet available">
                Videos
              </Tab>
            </Group>
            <Subtext>Images and Videos have extra options</Subtext>
          </Label>

          <Label as="div" text="Accept">
            <FileTypes type={tab} />
            <Subtext>Select only the file types you accept</Subtext>
          </Label>

          {tab === "image" && (
            <Label text="Image Size">
              <Half>
                <div>
                  <div>Width:</div>
                  <Input
                    name="height"
                    type="number"
                    min="0"
                    step="1"
                    placeholder="0"
                    defaultValue={folder.config.height}
                  />
                </div>
                <div>
                  <div>Height:</div>
                  <Input
                    name="width"
                    type="number"
                    min="0"
                    step="1"
                    placeholder="0"
                    defaultValue={folder.config.width}
                  />
                </div>
              </Half>
              <Subtext>Leave 0 to use the original width and height</Subtext>
            </Label>
          )}

          <Label as="div">
            <Button primary>Save Changes</Button>
          </Label>
        </Form>

        <hr />

        <Label as="div" text="Delete">
          <Button dangerous onClick={deleteFolder}>
            Delete Template
          </Button>
        </Label>
      </Modal.Body>
    </div>
  );
});
