import { useUrl, useQuery } from "crossroad";
import { useActions } from "statux";
import FormMate, { FormLoading } from "form-mate";
import styled from "styled-components";
import toast from "react-hot-toast";

import { Button, Input, Layout as BaseLayout, Spinner } from "../../components";
import api from "../../api";

const Layout = styled(BaseLayout)`
  margin: 10px auto;
  flex-direction: column;
`;

const Form = styled(FormMate)`
  position: relative;
  background: #fff;
  width: 100%;
  max-width: 400px;
  @media (min-width: 400px) {
    border-radius: var(--round-large);
  }
  margin: 0 auto;
  padding: calc(1.5 * var(--gap));
  box-shadow: var(--soft-shadow);

  h1 {
    font-weight: normal;
    color: #333;
    font-size: 26px;
  }
`;

const Logo = styled.img`
  width: 80px;
  margin: 20px auto 20px;

  @media (min-width: 500px) {
    width: 120px;
  }
`;

const Label = styled.label`
  display: flex;
  align-items: center;
  margin: 15px 0 0;
  border-radius: var(--round-small);
  cursor: pointer;

  @media (max-width: 500px) {
    flex-wrap: wrap;
  }

  span {
    flex-grow: 1;
    flex-shrink: 0;
  }
`;

const Bubble = styled.p`
  padding: 10px 15px;
  border: 1px solid #afa;
  border-radius: var(--round-small);
  margin-top: 10px;
  background: #efe;
`;

const Text = styled.div`
  width: 100%;
  flex-shrink: 0;
  @media (min-width: 500px) {
    width: 90px;
  }
`;

export default function Login() {
  const setUser = useActions("user");
  const [message] = useQuery("message");
  const [, setUrl] = useUrl();
  const onLogin = async (body) => {
    const user = await api.post("/login", body);
    localStorage.setItem("token", user.token);
    toast.success(`Welcome back, ${user.name}`);
    setUser(user);
    setUrl("/");
  };
  const onError = (err) => {
    toast.error(err.message || "Please try again later");
  };
  return (
    <Layout>
      <Logo src="/logo.svg" />
      <Form onSubmit={onLogin} onError={onError}>
        <FormLoading>{(loading) => <Spinner show={loading} />}</FormLoading>
        <h1>Filemon Demo</h1>
        {message && <Bubble>{message}</Bubble>}
        <Bubble>This is a read-only account!</Bubble>
        <Label>
          <Text>Email:</Text>
          <Input
            readOnly
            name="email"
            type="email"
            placeholder="john@doe.com"
            value="demo@filemon.io"
          />
        </Label>
        <Label>
          <Text>Password:</Text>
          <Input readOnly name="password" placeholder="******" value="demo" />
        </Label>
        <Label as="div">
          <Text></Text>
          <Button primary>Login</Button>
          <span></span>
          <a href="/register">Register</a>
        </Label>
      </Form>
    </Layout>
  );
}
