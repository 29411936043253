import styled from "styled-components";

import { Layout } from "../../components";

const Spinner = styled.div`
  position: relative;
  margin: 100px auto;
  width: 200px;
  height: 200px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 80px;
  }

  &:after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    margin: 0 auto;
    font-size: 10px;
    text-indent: -9999em;
    border-top: 1.6em solid rgba(0, 194, 245, 0.1);
    border-right: 1.6em solid rgba(0, 194, 245, 0.1);
    border-bottom: 1.6em solid rgba(0, 194, 245, 0.1);
    border-left: 1.6em solid #00c2f5;
    transform: translateZ(0);
    animation: load8 1s infinite -${() =>
        Math.round(new Date().getMilliseconds()) / 1000}s linear;
  }
  @keyframes load8 {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export default function Loading() {
  return (
    <Layout>
      <Spinner>
        <img src="/logo.svg" alt="Loading indicator" />
      </Spinner>
    </Layout>
  );
}
