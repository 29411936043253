import { useState } from "react";
import styled from "styled-components";
import api from "../../api";
import stripe from "../../stripe";

import { Button } from "../../components";

const Pricing = styled.section`
  margin: 30px auto 220px;
  width: 100%;
  width: calc(100% - 20px);
  max-width: 960px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  flex-grow: 0;
  flex-shrink: 1;
  gap: 20px;
  padding-bottom: 80px;
`;

const Content = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: var(--gap);
`;

const PricingPlan = styled.div`
  padding: 15px;
  border-radius: var(--round);
  @media (min-width: 960px) {
    width: calc(33.3333% - 40px / 3);
  }
  border: 1px solid #aaa;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 0;

  h3 {
    line-height: 1;
    font-size: 34px;
    margin-bottom: 30px;
    display: flex;
    justify-content: space-between;
    position: relative;

    &::after {
      content: "monthly";
      position: absolute;
      color: #666;
      top: 34px;
      right: 0;
      font-size: 14px;
    }
  }

  p {
    margin: 0 0 10px 0;
  }

  ul {
    margin: 0 0 10px 0;
    padding: 0 20px;
  }

  @media (min-width: 980px) {
    border: none;
    box-shadow: var(--shadow);
  }
`;

const Spacer = styled.div`
  flex-grow: 1;
`;

const StyledSpinner = styled.svg`
  animation: rotate 2s linear infinite;
  margin: 0 auto;
  width: 24px;
  height: 24px;

  & .path {
    stroke: var(--color-a);
    stroke-linecap: round;
    animation: dash 1.5s ease-in-out infinite;
  }

  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }
  @keyframes dash {
    0% {
      stroke-dasharray: 1, 150;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -35;
    }
    100% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -50;
    }
  }
`;

const Spinner = () => (
  <StyledSpinner>
    <circle
      className="path"
      cx="12"
      cy="12"
      r="8"
      fill="none"
      strokeWidth="2"
    />
  </StyledSpinner>
);

const StripeButton = ({ link }) => {
  const [loading, setLoading] = useState(false);

  const onClick = (e) => {
    if (loading) return;
    setLoading(true);
    api.get(link).then((sessionId) => {
      stripe.redirectToCheckout(sessionId);
    });
  };

  return (
    <Button onClick={onClick} disabled={loading}>
      {loading ? <Spinner /> : "Subscribe with Stripe"}
    </Button>
  );
};

export default function PricingSection() {
  return (
    <Pricing>
      <h2 id="pricing">Pricing</h2>
      <Content>
        <PricingPlan>
          <h3>
            <div>Commons</div>
            <div>$0</div>
          </h3>
          <p>
            Your images <strong>disappear in 1h</strong>. Availability is
            subject to server load. It's really{" "}
            <a
              target="_blank"
              rel="noreferrer"
              href="https://en.wikipedia.org/wiki/Tragedy_of_the_commons"
            >
              a tragedy
            </a>
            .
          </p>

          <Spacer />

          <Button as="a" primary href="/register">
            Get Started
          </Button>
        </PricingPlan>
        <PricingPlan>
          <h3>
            <div>Startup</div>
            <div>$10</div>
          </h3>
          <p>
            100 credits, resulting in either one of these, or a partial
            combination:
          </p>
          <ul>
            <li>100GB of data served</li>
            <li>100GB of data stored</li>
            <li>100K operations</li>
          </ul>

          <StripeButton link="/checkout-session?level=startup" />
        </PricingPlan>
        <PricingPlan>
          <h3>
            <div>Company</div>
            <div>$100</div>
          </h3>
          <p>
            1000 credits, resulting in either one of these, or a partial
            combination:
          </p>
          <ul>
            <li>1TB of data served</li>
            <li>1TB of data stored</li>
            <li>1M operations</li>
          </ul>

          <StripeButton link="/checkout-session?level=company" />
        </PricingPlan>
      </Content>
    </Pricing>
  );
}
