import { useActions } from "statux";
import styled from "styled-components";
import { AlertCircle, Settings } from "react-feather";

import Card from "./Card";
import { Button, Squircle } from "../../components/";

const baseURL = process.env.REACT_APP_API;

const Group = styled.a`
  ${Card};
  min-height: 150px;

  width: calc(100% + 20px);
  margin: 0 -10px;
  border-radius: 0;
  @media (min-width: 960px) {
    width: calc(33.333% - 10px * 3 / 2);
    margin: 0;
    border-radius: 10px;
    &:hover {
      transform: scale(1.03);
    }
  }

  display: block;
  padding: var(--gap);
  color: inherit;
  text-decoration: none;
  cursor: pointer;
  transition: all 0.2s ease;
`;

const Gallery = styled.div`
  width: calc(100% + 12px);
  margin-left: -4px;
  margin-bottom: -4px;
  max-width: 960px;
  flex-wrap: wrap;
  display: flex;
  gap: 0;
  overflow: hidden;

  & > * {
    display: block;
    width: calc(100% / 3);
    aspect-ratio: 1;
    flex-grow: 0;
    flex-shrink: 0;
    overflow: hidden;
  }
`;

const Preview = styled(Squircle)`
  position: relative;
  background: #fff;
  margin-right: calc(-100% / (3 * 3));
  z-index: ${(p) => p.index};
`;

const Img = styled(Squircle)`
  width: calc(100% - 8px);
  height: calc(100% - 8px);
  margin: 4px;
`;

const Count = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  line-height: 1;
  color: white;
  padding: 3px 5px;
  border-radius: 3px;

  & + ${Img} {
    filter: brightness(0.5);
  }
`;

const Header = styled.header`
  width: 100%;
  display: flex;
  margin-top: var(--gap);
  align-items: middle;
`;

const CardHeader = styled.header`
  width: 100%;
  display: flex;
  line-height: 20px;
  margin-bottom: 5px;
  padding: 0 2px;
  align-items: center;
`;

const Title = styled.h2`
  width: 100%;
  line-height: 30px;
  font-weight: normal;
`;

const Name = styled.h3`
  font-size: inherit;
  flex-grow: 1;
  line-height: 20px;
  font-weight: normal;
`;

const AddTemplate = styled(Group)`
  background: none;
  box-shadow: none;
  border: 2px dashed #aaa;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
`;

const Options = styled.div`
  position: absolute;
  z-index: 10;
  opacity: 0.75;
  background: #eee;
  transition: all 0.15s ease;
  padding: 6px;
  top: 6px;
  right: 6px;
  @media (min-width: 600px) {
    top: 15px;
    right: 15px;
    padding: 5px;
  }
  border-radius: var(--round-small);

  :hover {
    background: #ddd;
  }
`;

const Info = ({ children }) => (
  <Options
    onClick={(e) => {
      e.preventDefault();
      e.stopPropagation();
      alert(children);
    }}
  >
    <AlertCircle size={18} />
  </Options>
);

const SettingsButton = ({ folder }) => {
  const setModal = useActions("modal");
  return (
    <Options
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        setModal({ id: "settings", folder });
      }}
    >
      <Settings size={18} />
    </Options>
  );
};

export default function Folders({ folders }) {
  const setModal = useActions("modal");
  return (
    <>
      <Header>
        <Title>Templates</Title>
        <Button onClick={() => setModal("template")}>+ Create Template</Button>
      </Header>
      {folders.map((folder) => (
        <Group key={folder.id} href={`/folder/${folder.id}`}>
          {folder.id === "all" ? (
            <Info>
              These are all the images that have ever been uploaded, whether
              they belong to a folder or not
            </Info>
          ) : folder.id === "unclassified" ? (
            <Info>
              These are the files that have been uploaded to the bucket but do
              not belong to a folder
            </Info>
          ) : (
            <SettingsButton folder={folder.id} />
          )}
          <CardHeader>
            <Name>{folder.name}</Name>
          </CardHeader>
          <Gallery>
            {folder.files.slice(0, 4).map((file, i) => (
              <Preview index={4 - i} key={file.id}>
                {folder.count > 4 && i === 3 && (
                  <Count>+{Math.max(0, folder.count - 3)}</Count>
                )}
                <Img>
                  <img src={`${baseURL}preview/${file.id}`} alt="" />
                </Img>
              </Preview>
            ))}
          </Gallery>
        </Group>
      ))}
      <AddTemplate onClick={() => setModal("template")}>
        + Create Template
      </AddTemplate>
    </>
  );
}
