import styled from "styled-components";
import { Home } from "react-feather";

const H1 = styled.h1`
  font-weight: normal;
  line-height: 1;
  width: 100%;

  a {
    color: inherit;
    text-decoration: none;
    display: inline-block;
  }

  svg,
  img {
    display: inline-block;
    vertical-align: bottom;
  }
`;

export default function Title({ children }) {
  return (
    <H1>
      <a href="/">
        <Home /> Dashboard
      </a>
      {children && (
        <>
          &nbsp;&nbsp;‣&nbsp;&nbsp;
          {children}
        </>
      )}
    </H1>
  );
}
