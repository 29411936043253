import styled from "styled-components";

const FooterContainer = styled.footer`
  background: #333;
  color: #fff;
  z-index: -1;
  box-shadow: inset 0 10px 10px -10px black;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 220px;
  padding: 30px calc(50% - 480px);

  h2 img {
    display: inline-block;
    height: 30px;
    width: 30px;
    line-height: 30px;
    vertical-align: middle;
    margin-top: -6px;
    margin-right: 2px;
  }

  p {
    margin-bottom: 15px;
  }
`;

const FlexContainer = styled.div`
  display: flex;
  gap: 10px;
`;

const Col = styled.div`
  flex-grow: 1;
  flex-shrink: 0;
  padding: 15px;
`;

export default function Footer() {
  return (
    <FooterContainer>
      <FlexContainer>
        <Col>
          <h2>
            <img alt="logo" width="30px" src="/logo.svg" /> Filemon
          </h2>
          <p>An API to manage images, videos, and other files.</p>
          <div>Terms of Service • Privacy Policy</div>
        </Col>
        <Col>
          <h3>Navigation</h3>
          <div>Home</div>
          <div>Documentation</div>
          <div>Pricing</div>
          <div>Register</div>
        </Col>
        <Col>
          <h3>About</h3>
          <div>Who are we?</div>
          <div>Motivation</div>
          <div>Forgot Password?</div>
          <div>Need help?</div>
        </Col>
      </FlexContainer>
    </FooterContainer>
  );
}
