import Router, { Route, Switch } from "crossroad";
import Store from "statux";
import { Toaster } from "react-hot-toast";

import Auth, { withUser, dualAuth } from "./Auth";

import * as pages from "./pages";

const Modals = pages.Modals;

export default function App() {
  return (
    <Store>
      <Toaster />
      <Modals />
      <Auth>
        <Router scrollUp>
          <Switch>
            <Route path="/" component={dualAuth(pages.Dashboard, pages.Home)} />
            <Route path="/documentation" component={pages.Documentation} />
            <Route path="/folder/:id/*" component={withUser(pages.Folder)} />
            <Route path="/account" component={withUser(pages.Account)} />
            <Route path="/settings" component={withUser(pages.Settings)} />
            <Route path="/login" component={pages.Login} />
            <Route path="/demo" component={pages.Demo} />
            <Route path="/register" component={pages.Register} />
            <Route path="/verify" component={pages.Verify} />
          </Switch>
        </Router>
      </Auth>
    </Store>
  );
}
