// Wraps the body and builds the single column layout we are used to
import styled from "styled-components";
import Nav from "../Nav";

const Wrap = styled.div`
  position: relative;
  background: linear-gradient(180deg, #e6f8f2 90%, white);
  /* background: #e6f8f2; */
  width: 100%;
  min-height: 100vh;
  margin: 50px auto 0;
  padding-bottom: 50px;
  overflow-x: hidden;
`;

const Content = styled.main`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  max-width: 960px;
  margin: 0 auto;

  h1 {
    font-size: 26px;
    font-weight: normal;
  }
`;

export default function Main({ className, children }) {
  return (
    <Wrap>
      <Nav />
      <Content className={className}>{children}</Content>
    </Wrap>
  );
}
