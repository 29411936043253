import toast from "react-hot-toast";
import styled from "styled-components";
import { Compass, Clipboard } from "react-feather";

import Card from "./Card";

const InputWrap = styled.div`
  ${Card};

  display: flex;
  height: 50px;
  padding: 0;
  width: 100%;
  @media (min-width: 960px) {
    padding: 0;
    width: calc(50% - 20px * 2 / 4);
  }

  input {
    padding: 15px 1px;
    border: none;
    width: 100%;
    height: 50px;
    background: none;
  }
  input:active,
  input:focus {
    outline: none;
    border: none;
  }

  svg {
    margin: 14px 10px 15px 15px;
  }
`;

const CopyClick = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  height: 50px;
  z-index: 10;
  padding: 0 10px 0 5px;
  cursor: pointer;
  transition: all 0.15s ease;
  :hover {
    background: rgba(255, 255, 255, 0.5);
  }

  svg {
    transition: all 0.15s ease;
  }

  :hover svg {
    transform: scale(1.1);
  }
  :active svg {
    transform: scale(1);
  }
`;

export default function InputCard({ value }) {
  const copy = () => {
    navigator.clipboard.writeText(value);
    toast.success("Copied to your clipboard");
  };
  return (
    <InputWrap>
      <Compass />
      <input readOnly value={value} />
      <CopyClick onClick={copy} title="Copy">
        <Clipboard size={22} />
      </CopyClick>
    </InputWrap>
  );
}
