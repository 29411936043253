import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "statux";
import styled from "styled-components";
import { Database, Layers, Repeat, Tool } from "react-feather";

import Card from "./Card";
import { Info } from "../../components";

const Group = styled.div`
  ${Card};
  display: block;
  width: 100%;
  padding: var(--gap);
  min-height: calc(130px - 2 * var(--gap));
  @media (min-width: 800px) {
    width: calc(50% - 10px);
  }

  h2,
  h3 {
    font-weight: normal;
    font-size: 18px;
    line-height: 1;
    margin-bottom: 10px;
  }
`;

const Bar = styled.div`
  margin: 0;
  border: 1px solid #aaa;
  border-radius: 6px;
  overflow: hidden;
  background: #fff;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: 30px;
`;

const BarItem = styled.div`
  border-right: 1px solid #aaa;
  background: ${(p) => p.color};
  width: ${(p) => p.width || 0}%;
  min-width: 10px;
  height: 100%;
`;

const LabelBox = styled.div`
  width: 100%;
  display: flex;
  margin-top: 10px;
  flex-wrap: wrap;
  gap: 10px;
`;

const LabelWrap = styled.div`
  width: calc(50% - 10px);
  font-size: 14px;
  display: flex;
  align-items: center;
  border-radius: 6px;
  text-align: center;
  position: relative;
`;

const Circle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  border-radius: 15px;
  background: ${(p) => p.color};
  border: 1px solid #aaa;
  margin-right: 5px;
`;

const Label = ({ title, children }) => {
  return (
    <LabelWrap title={title}>
      {children}
      <Info>{title}</Info>
    </LabelWrap>
  );
};

const Wrapper = styled.div`
  position: absolute;
  top: 25px;
  left: 5px;
  bottom: 5px;
  right: 5px;
`;

const Svg = styled.svg`
  line {
    stroke: #aaa;
    stroke-dasharray: 4;
  }

  text {
    opacity: 0.6;
    transition: all 0.2s ease;
    font-size: 10px;
    dominant-baseline: middle;
    text-anchor: middle;
  }

  path {
    fill: none;
    stroke: #ffc84b;
    stroke-width: 2px;
  }

  circle {
    r: 4px;
    fill: #00c2f5;
  }
`;

const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

const toDateDiff = (num) =>
  new Date(new Date().setDate(new Date().getDate() - num));

const Graph = () => {
  const history = useSelector("user.history");
  const [size, setSize] = useState([0, 0]);
  const ref = useRef();
  useEffect(() => {
    const rect = ref.current.getBoundingClientRect();
    setSize([rect.width, rect.height]);
    const onResize = () => {
      const rect = ref.current.getBoundingClientRect();
      setSize([rect.width, rect.height]);
    };
    window.addEventListener("resize", onResize);
    return () => window.removeEventListener("resize", onResize);
  }, []);

  const data = history.map((entry) => entry.used);
  let max = Math.max(...data);
  const min = Math.min(...data);
  if (max === min) {
    max = 0.1;
  }
  const width = size[0];
  const height = size[1];
  const points = data.map((h, i, t) => [
    (i + 0.25) * (width / (t.length - 0.5)),
    height - (height - 50) * (h / max) - 25,
  ]);
  const d = `M ${points.map((p) => `${p[0]},${p[1]}`).join("L ")}`;
  return (
    <Wrapper ref={ref}>
      <Svg height={height} width={width}>
        <path d={d} />
        {points.map((p, i, a) => (
          <React.Fragment key={toDateDiff(a.length - i - 1).getDate()}>
            <line x1={p[0]} y1={height - 20} x2={p[0]} y2={p[1]} />
            <circle cx={p[0]} cy={p[1]} />
            <text x={p[0]} y={p[1] - 12} title={data[i]}>
              {Math.round(1000 * data[i]) / 1000}
            </text>
            <text x={p[0]} y={height - 10}>
              {toDateDiff(a.length - i - 1).getDate()}{" "}
              {months[toDateDiff(a.length - i - 1).getMonth()]}
            </text>
          </React.Fragment>
        ))}
      </Svg>
    </Wrapper>
  );
};

export default function Usage() {
  const { served, stored, ops, free, total } = useSelector("user.credits");

  return (
    <>
      <Group>
        <h2>
          Credits used this month{" "}
          <Info>This is your credit usage for this month</Info>
        </h2>
        <Bar>
          <BarItem
            color="#b1ffff"
            width={(100 * served) / total}
            title={served + "GB"}
          />
          <BarItem
            color="#b3ffb3"
            width={(100 * stored) / total}
            title={stored + "GB"}
          />
          <BarItem
            color="#ffda98"
            width={(100 * ops) / total}
            title={ops + "K OPS"}
          />
        </Bar>
        <LabelBox>
          <Label title={served + " GB of files served"}>
            <Circle color="#b1ffff">
              <Repeat size="14" />
            </Circle>
            {Math.round(served)}GB transfered
          </Label>
          <Label title={stored + " GB of files stored"}>
            <Circle color="#b3ffb3">
              <Database size="14" />
            </Circle>
            {Math.round(stored)}GB stored
          </Label>
          <Label title={ops * 1000 + " operations performed"}>
            <Circle color="#ffda98">
              <Tool size="14" />
            </Circle>
            {Math.round(ops)}K operations
          </Label>
          <Label title={free + " credits available"}>
            <Circle color="#fff">
              <Layers size="14" />
            </Circle>
            {Math.round(free)} credits available
          </Label>
        </LabelBox>
      </Group>

      <Group>
        <h2>Daily credit usage</h2>
        <Graph />
      </Group>
    </>
  );
}
