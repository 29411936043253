import { useEffect, useMemo, useRef, useState } from "react";
import { useStore, useActions } from "statux";
import styled from "styled-components";
import { Folder } from "react-feather";
import toast from "react-hot-toast";
import Form from "form-mate";
import { useParams, usePath } from "crossroad";

import UploadFile from "./UploadFile";
import Lightbox from "./Lightbox";
import api from "../../api";
import {
  Button,
  Layout as BaseLayout,
  Title,
  Squircle,
} from "../../components/";

const baseURL = process.env.REACT_APP_API;

const Layout = styled(BaseLayout)`
  margin: calc(2 * var(--gap)) auto 50px;
  gap: var(--gap);

  @media (max-width: 960px) {
    padding: 10px;
  }
`;

const GalleryStyle = styled(Form)`
  display: block;
  width: 100%;
  max-width: 960px;
  min-heigth: 500px;
  position: relative;

  fieldset > div {
    display: block;
    position: relative;
    width: 100%;
    max-width: 960px;
    flex-wrap: wrap;
    display: flex;
    gap: var(--gap);
  }
`;

const Rect = styled.div.attrs((p) => ({
  style: {
    top: `${p.start[1] - 50}px`,
    left: `${p.start[0]}px`,
    height: `${p.end[1] - p.start[1]}px`,
    width: `${p.end[0] - p.start[0]}px`,
  },
}))`
  content: "";
  display: block;
  background: rgba(0, 0, 0, 0.3);
  position: absolute;
  z-index: 1000;
  pointer-events: none;
`;

const inside = (start, end, p) => {
  return p[0] > start[0] && p[0] < end[0] && p[1] > start[1] && p[1] < end[1];
};

const Gallery = ({ children, setChecked, ...props }) => {
  const ref = useRef();
  const [a, setA] = useState(null);
  const [b, setB] = useState(null);
  const start = useMemo(
    () => a && b && [Math.min(a[0], b[0]), Math.min(a[1], b[1])],
    [a, b]
  );
  const end = useMemo(
    () => a && b && [Math.max(a[0], b[0]), Math.max(a[1], b[1])],
    [a, b]
  );
  useEffect(() => {
    const onMouseDown = (e) => {
      setA([e.pageX, e.pageY]);
    };
    const onMouseMove = (e) => {
      setB([e.pageX, e.pageY]);
    };
    const onMouseUp = (e) => {
      if (!start || !end || (end[0] - start[0] < 5 && end[1] - start[1] < 5)) {
        setA(null);
        setB(null);
        return;
      }
      const imgs = ref.current.querySelectorAll("img");
      const ids = [];
      imgs.forEach((img) => {
        const rect = img.getBoundingClientRect();
        const r = (n) => Math.round(n);
        if (
          !inside(
            [r(start[0] - rect.width / 2), r(start[1] - rect.height / 2)],
            [r(end[0] + rect.width / 2), r(end[1] + rect.height / 2)],
            [
              r(rect.x + rect.width / 2),
              r(rect.y + rect.height / 2 + window.scrollY),
            ]
          )
        )
          return;
        ids.push(img.id);
      });
      setChecked(ids);
      setA(null);
      setB(null);
    };
    const onKeyPress = (e) => {
      if (e.key === "Escape") {
        setA(null);
        setB(null);
      }
    };
    window.addEventListener("keydown", onKeyPress);
    window.addEventListener("mousedown", onMouseDown);
    window.addEventListener("mousemove", onMouseMove);
    window.addEventListener("mouseup", onMouseUp);
    return () => {
      window.removeEventListener("keydown", onKeyPress);
      window.removeEventListener("mousedown", onMouseDown);
      window.removeEventListener("mousemove", onMouseMove);
      window.removeEventListener("mouseup", onMouseUp);
    };
  }, [start, end, setChecked]);
  return (
    <>
      {start && end && <Rect start={start} end={end} />}
      <GalleryStyle {...props}>
        <div ref={ref}>{children}</div>
      </GalleryStyle>
    </>
  );
};

const Card = styled(Squircle)`
  position: relative;
  display: block;
  width: calc(100% / 3 - var(--gap) * 2 / 3);
  @media (min-width: 960px) {
    width: calc(100% / 6 - var(--gap) * 5 / 6);
  }

  background: #aaa;
  aspect-ratio: 1;
  flex-grow: 0;
  flex-shrink: 0;
  overflow: hidden;
  cursor: pointer;
  transition: transform 0.15s ease;
  -webkit-touch-callout: none;

  :hover {
    transform: scale(1.05);
  }

  [type="checkbox"] {
    position: absolute;
    pointer-events: none;
    top: 20px;
    left: 20px;
    z-index: 10;
    border: none;
    opacity: 0;
    transform: scale(1.5);
    cursor: pointer;
  }

  [type="checkbox"]:checked {
    opacity: 1;
  }

  img {
    user-select: none;
  }
`;

const DeleteButton = styled.button`
  background: red;
  border-radius: 6px;
  border: none;
  padding: 3px 10px;
  color: white;
  cursor: pointer;

  &:disabled {
    opacity: 0.5;
    filter: saturate(0);
    cursor: not-allowed;
  }
`;

const Actions = styled.div`
  padding: var(--gap);
  background: #fff;
  width: 100%;
  border-radius: var(--round);
  margin-top: var(--gap-small);
  margin-bottom: var(--gap);
  display: flex;
  gap: var(--gap);
  align-items: center;
`;

const useOnDelete = ({ active, enabled, after }) => {
  const setUser = useActions("user");
  return async function onDelete(e) {
    e.preventDefault();
    toast.promise(
      (async () => {
        if (!active || !active.length) throw new Error("Nothing selected");
        await api.delete("/", { data: active });
        const newUser = await api.get("/admin");
        setUser(newUser);
        if (after) after();
      })(),
      {
        loading: "Deleting...",
        success: `Image${active.length > 1 ? "s" : ""} deleted!`,
        error: (err) => err.message || `Could not delete.`,
      }
    );
  };
};

const MenuStyle = styled.div`
  position: absolute;
  top: ${(p) => p.top - 50 - 15}px;
  left: ${(p) => p.left - 15}px;
  z-index: 10000;
  background: white;
  box-shadow: var(--shadow-hard);
  border-radius: var(--round-small);
  width: 150px;
  overflow: hidden;

  a,
  button {
    text-decoration: none;
    color: inherit;
    display: block;
    width: 100%;
    padding: 10px 15px;
    cursor: pointer;
    border: none;
    background: #fff;
    text-align: left;
    transition: all 0.15s ease;

    &.dangerous {
      background: #fcc;
    }

    :hover {
      filter: brightness(0.9) saturate(2);
    }
  }

  hr {
    border: none;
    border-bottom: 1px solid #ccc;
    background: none;
    height: 0;
  }
`;

const Menu = ({ pos, setPos, children }) => {
  const ref = useRef();
  useEffect(() => {
    const onClick = (e) => {
      // If clicked within the popup, ignore it
      // if (ref.current.contains(e.target)) return null;
      setPos(null);
    };
    window.addEventListener("click", onClick);
    return () => window.removeEventListener("click", onClick);
  }, [setPos]);
  if (!pos) return null;
  return (
    <MenuStyle ref={ref} top={pos.y} left={pos.x}>
      {children}
    </MenuStyle>
  );
};

const unique = (value, index, self) => {
  return self.indexOf(value) === index;
};

export default function Admin() {
  const [user, setUser] = useStore("user");
  const [path] = usePath();
  const [pos, setPos] = useState(null);
  const [checked, setChecked] = useState([]);
  const { id } = useParams();

  const active = [...checked, pos?.id].filter(Boolean).filter(unique);

  const onDelete = useOnDelete({
    active,
    enabled: Boolean(checked.length),
    after: () => setChecked([]),
  });

  const updateUser = async () => {
    const newUser = await api.get("/admin");
    setUser(newUser);
  };

  const folder = user.folders.find((f) => f.id === id);

  return (
    <>
      <Lightbox />
      <Layout>
        <Title>
          <Folder /> {folder.name}
        </Title>
        Click to open in popup, [cmd] + click to select, right click for
        actions, drag mouse to select
        <Actions>
          <Button
            onClick={() => setChecked(folder.files.map((file) => file.id))}
          >
            Select All
          </Button>
          <Button onClick={() => setChecked([])}>Unselect All</Button>
          <div>|</div>
          <DeleteButton onClick={onDelete} disabled={!active.length}>
            Delete file{active.length >= 2 ? "s (" + active.length + ")" : ""}
          </DeleteButton>
        </Actions>
        <Menu pos={pos} setPos={setPos}>
          <a href={window.location.pathname + "/" + pos?.id}>See info</a>
          <button>
            Open file{active.length >= 2 ? "s (" + active.length + ")" : ""}
          </button>
          <hr />
          <button className="dangerous" onClick={onDelete}>
            Delete file{active.length >= 2 ? "s (" + active.length + ")" : ""}
          </button>
        </Menu>
        <Gallery
          setChecked={setChecked}
          onChange={(data) => setChecked(Object.keys(data))}
        >
          <UploadFile onUpload={updateUser} />
          {folder.files.map((file, i) => (
            <Card key={file.id}>
              <a
                href={path + "/" + file.id}
                onClick={(e) => {
                  if (e.metaKey) {
                    e.preventDefault();
                    e.stopPropagation();
                    if (checked.includes(file.id)) {
                      setChecked((all) => all.filter((c) => c !== file.id));
                    } else {
                      setChecked([...checked, file.id]);
                    }
                  }
                }}
                onContextMenu={(e) => {
                  e.preventDefault();
                  setPos({ id: file.id, x: e.pageX, y: e.pageY });
                }}
              >
                <input
                  type="checkbox"
                  name={file.id}
                  value="checked"
                  readOnly
                  checked={active.includes(file.id)}
                />
                <img
                  id={file.id}
                  src={`${baseURL}preview/${file.id}`}
                  draggable="false"
                  alt=""
                />
              </a>
            </Card>
          ))}
        </Gallery>
      </Layout>
    </>
  );
}
