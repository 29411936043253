import CreateTemplate from "./CreateTemplate";
import Settings from "./Settings";

export default function AllModals() {
  return (
    <>
      <CreateTemplate />
      <Settings />
    </>
  );
}
