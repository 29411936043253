import styled from "styled-components";
import { useEffect } from "react";

const MainElement = styled.main`
  background: #fff;
`;

const useBackgroundColor = ({ top, bottom }) => {
  useEffect(() => {
    const onScroll = (e) => {
      const style = document.documentElement.style;
      const height = document.documentElement.scrollHeight;
      const h = window.scrollY / (height - window.innerHeight);
      const newColor = h < 0.5 ? top : bottom;
      if (style.background !== newColor) {
        style.background = newColor;
      }
    };
    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, [top, bottom]);
};

export default function Main({ children }) {
  useBackgroundColor({ top: "#fff", bottom: "#333" });
  return <MainElement>{children}</MainElement>;
}
